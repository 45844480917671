<template>
    <div>
        <card-header title="New Question" icon="fa-plus"/>

        <card-body>
            <question-form :question="newQuestion"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newQuestion.question || !newQuestion.type" @click="submit"><i class="fas fa-plus mr-3"></i>New Question</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import QuestionForm from "@/cards/trips/QuestionForm";

    export default {
        name: "NewEvent",
        props: ['card', 'props'],
        components: {QuestionForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function () {
            return {
                newQuestion: {},
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/questions/', {trip_id: this.props.tripId, ...this.newQuestion}, {force: true}).then(response => {
                    this.$reloadCard('questions', {tripId: this.props.tripId});
                    this.$reloadCard('trip', {dayId: this.props.tripId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new question? Unsaved changes will be lost.');
        }
    };
</script>
